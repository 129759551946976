.MuiTabs-flexContainer.MuiTabs-centered {
  > button {
    margin-right: 0 !important;
  }
}

.MuiTabs-centered {
  justify-content: space-around !important;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.review-screen .react-swipeable-view-container {
  height: 100%;
}

.review-screen .react-swipeable-view-container > div {
  overflow: hidden;
}

.review-screen .recharts-wrapper > svg {
  width: 100%;
  height: auto;
  margin-left: -20px;
}

.review-screen .recharts-wrapper > svg text {
  font-size: 12px;
}


.loader-37 {
  border-right: 0.1em solid currentcolor;
  border-radius: 100%;
  -webkit-animation: loader-37 800ms linear infinite;
  animation: loader-37 800ms linear infinite;
}
.loader-37:before, .loader-37:after {
  content: "";
  width: 0.8em;
  height: 0.8em;
  display: block;
  position: absolute;
  top: calc(50% - .4em);
  left: calc(50% - .4em);
  border-left: 0.08em solid currentcolor;
  border-radius: 100%;
  animation: loader-37 400ms linear infinite reverse;
}
.loader-37:after {
  width: 0.6em;
  height: 0.6em;
  top: calc(50% - .3em);
  left: calc(50% - .3em);
  border: 0;
  border-right: 0.05em solid currentcolor;
  -webkit-animation: none;
  animation: none;
}

@-webkit-keyframes loader-37 {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes loader-37 {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.grant-button,
.blue-gradient-button{
  background: rgb(30,184,215);
  background: radial-gradient(circle, rgba(30,184,215,1) 15%, rgba(83,103,250,1) 100%);
  letter-spacing: 1px;
}


