.banking-mobile {
  .tutor-banking-navbar {
    margin-left: 0 !important;
    width: 100vw !important;
  }

  .tutor-banking-main {
    height: auto !important;

    .bank-details-tab {
      flex-direction: column;

      .account-tab,
      .upi-tab {
        border-right: unset !important;
        border-bottom: 2px dashed rgba(128, 128, 128, 0.459);
        width: 100% !important;
        padding-left: 0 !important;

        .account-row,
        .upi-row {
          margin: 20px 0;

          h2 {
            font-size: 14px !important;
            width: 170px !important;
            opacity: 0.46 !important;
          }

          input {
            font-size: 13px !important;
            padding: 5px 10px !important;
          }
        }
      }

      .upi-tab {
        border-bottom: unset;
        margin-top: 20px;
      }
    }
  }

  .tutor-earnings-wrapper {
    flex-direction: column;

    .tutor-banking-wrapper {
      margin-left: 0 !important;
      width: 100vw !important;
      min-height: unset !important;
      margin-bottom: 0 !important;
    }

    .tutor-payout-info-container {
      width: 100vw !important;
      padding: 0 15px;
      height: auto !important;

      .tutor-payment-list {
        height: auto !important;
        //max-height: 300px;
        //overflow: auto;
      }
    }

    .tutor-payment-list.tutor-help {
      height: 350px !important;
      margin-bottom: 100px;
    }


    .tutor-banking-chart,
    .tutor-hours,
    .tutor-sessions,
    .tutor-earnings,
    .tutor-payment-list,
    .tutor-payment-list.tutor-help{
      background: unset !important;
      border: unset !important;
      box-shadow: unset !important;
    }

    .tutor-earnings {
      padding-bottom: 0 !important;
    }

    .tutor-banking-chart {
      padding: 5px;

      .recharts-responsive-container.main-chart {
        height: 200px !important;
        margin-top: 15px;
      }

      .banking-timeline {
        h1 {
          font-size: 17px;

          span {
            line-height: 1.4;
          }
        }
      }
    }

    .tutor-hours,
    .tutor-sessions {
      height: 165px !important;

      .tutor-container {

        h4 {
          font-size: 20px !important;
        }

        h2 {
          font-size: 25px !important;
        }

        button {
          bottom: -33px !important;
        }
      }
    }
  }
}
