.tutor-earnings-wrapper {

  &.page {
    width: 100%;
    height: 100%;

    .tutor-payout-info-container {
      width: 100vw;
      margin: 0!important;
      height: 100% !important;
      background: var(--color-primary);

      .tutor-payment-list {
        border: none;
        outline: none;
        background: none;
        box-shadow: none;
        height: 100% !important;
        height: 100%;

        h1 {
          margin: 10px 0;
        }
      }
    }
  }
}
