.call {
  text-align: center;
  height: 100vh;
  background: #080808fb;
  padding: 40px 40px;
  display: flex;
  align-items: center;
  gap: 10px;

  .call_loading {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;

    div {
      width: 52px !important;
      filter: invert(1) saturate(0);
    }
  }

  @media screen and (max-width: 500px) {
    padding: 0px;
    height: 100%;
  }

  .call__blaze__wrapper {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    overflow: hidden;
    flex: 1;
    width: 100%;
    //animation: moveRight 0.3s ease both;
    //
    //&.keep__aside {
    //  animation: moveLeft 0.3s ease both;
    //}
  }

  .call__blaze__head {
    position: absolute;
    z-index: 999;
    left: 50%;
    top: 40px;
    transform: translateX(-50%);
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.404));
    .call__blaze__logo {
      width: 150px;
    }
  }

  .student-container {
    position: relative;
  }

  .player-container {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .main__video__bg {
    width: calc(100vw - 80px);
    height: calc(100vh - 120px);

    @media screen and (max-width: 500px) {
      width: calc(100vw);
      height: calc(100vh);
    }

    .main__bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: blur(2.5px) brightness(0.65) saturate(0.85);
      border-radius: 10px;
    }

    .main__placeholder {
      z-index: 5;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 108px;
      height: 108px;
      border-radius: 50%;
      object-fit: cover;
      object-position: top;
    }
  }

  .student__video__bg {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    aspect-ratio: 16 / 9;

    .student__bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: blur(2px) brightness(0.85) saturate(0.85);
      border-radius: 10px;
    }

    .student__placeholder {
      z-index: 5;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 72px;
      height: 72px;
      border-radius: 50%;
      object-fit: cover;
      object-position: top;
    }
  }

  .button-group {
    position: absolute;
    z-index: 999;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0px 30px;
    padding-top: 5px;
    background: rgba(36, 36, 36, 0.575);
    border-radius: 10px 10px 0 0;
    backdrop-filter: blur(5px);

    .bill-duration {
      p {
        color: white;
        text-align: center;
        width: 100%;
      }
    }

    > div {
      display: flex;
    }

    button {
      margin: 10px;
      color: white;
      background: #333333;
      padding: 5px 10px;
      border-radius: 50%;
      height: 50px;
      width: 50px;
    }

    .chat-icon {
      svg {
        margin-top: 2px;
        padding: 2px !important;
      }
      position: relative;

      &.on {
        background: rgb(204, 204, 204);
        color: rgb(14, 13, 13);
      }

      .unread-dot {
        position: absolute;
        width: 12.5px;
        height: 12.5px;
        border-radius: 50%;
        background: red;
        top: 0px;
        right: 0px;
        border: 2px solid #181818;
      }
    }

    .leave-btn {
      background: rgb(217, 48, 37);
    }

    .off {
      background: rgb(204, 204, 204);
      color: rgb(14, 13, 13);
    }
  }
}

.local-player-wrapper {
  width: 100%;
  //height: 100%;
  background: #202020;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  height: 100%;
  aspect-ratio: 16 / 9;

  @media screen and (max-width: 500px) {
    //width: calc(100vw - 0px);
    //height: calc(100vh - 0px);
    border-radius: 0px;
    aspect-ratio: 9 / 16;
  }
}

.remote-player-wrapper {
  width: 30%;
  //height: 200px;
  z-index: 999;
  position: absolute;
  right: 40px;
  bottom: 40px;
  border-radius: 10px;
  background: #202020;
  border: 2px solid rgba(128, 128, 128, 0.15);
  overflow: hidden;

  &.placeholder {
    background: transparent;
  }

  @media screen and (max-width: 500px) {
    right: 20px;
    bottom: 100px;
    width: 130px;
    height: 230px;
  }
}

.student__name {
  position: absolute;
  bottom: 12.5px;
  left: 12.5px;
  color: rgb(255, 255, 255);
  text-shadow: 0 0 15px rgba(0, 0, 0, 0.5);

  @media screen and (max-width: 500px) {
    top: 10px;
    left: 10px;
    font-size: 14px;
  }
}

.student__mic,
.instructor__mic {
  position: absolute;
  top: 12.5px;
  right: 12.5px;
  background: rgba(0, 0, 0, 0.35);
  padding: 4px;
  border-radius: 50%;
  svg {
    color: white;
    width: 20px;
    height: 20px;
  }

  @media screen and (max-width: 500px) {
    top: 5px;
    right: 5px;
    transform: scale(0.75);
  }
}

.video-player {
  margin: auto auto;

  &.portrait {
    aspect-ratio: 9 / 16 !important;
    height: 100% !important;
  }

  &.landscape {
    aspect-ratio: 16 / 9 !important;
    width: 100% !important;
  }

  &.main {

    @media screen and (max-width: 500px) {
      //width: calc(100vw - 0px);
      //height: calc(100vh - 0px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .agora_video_player {
        border-radius: 0px !important;
      }
    }

    > div {
      margin: auto;
      background-color: transparent !important;
    }
  }

  &.student {
    width: 100%;
    height: 100%;
    > div {
      background-color: transparent !important;
    }

    .agora_video_player {
      overflow: hidden;
    }
  }
}

.chat-messages {
  //position: absolute;
  top: 3vh;
  right: 0;
  //width: 360px;
  background: var(--color-primary);
  border-radius: 10px;
  height: 100%;
  overflow: hidden;
  flex-shrink: 0;
  width: 0;

  &.slideInside {
    animation: slideInside 0.3s ease both;
  }

  &.slideOutside {
    animation: slideOutside 0.3s ease both;
  }

  @media screen and (max-width: 500px) {
    //width: 100vw;
    position: absolute;
    top: 0;
    height: 100%;
    border-radius: 0;
    z-index: 1000;
    &.slideInside {
      animation: slideInsideToScreen 0.3s ease both;
    }

    &.slideOutside {
      animation: slideOutsideToScreen 0.3s ease both;
    }

    .user__chat__window {
      height: calc(100% - 130px) !important;
    }
  }

  .chat-messages-head {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(128, 128, 128, 0.15);
    h1 {
      color: var(--color-text);
      text-align: left;
      padding-top: 2.5px;
      font-weight: 500;
      font-size: 18px;
    }

    svg {
      color: var(--color-text);
      cursor: pointer;
      font-size: 32px;
      padding: 6px;
      transition: 0.125s ease all;
      border-radius: 50%;

      &:hover {
        background: var(--color-focus);
        transition: 0.125s ease all;
      }
    }
  }

  .user__chat__window {
    height: calc(100% - 130px);
    overscroll-behavior: contain;
    padding-top: 10px;
    overflow-y: scroll;

    mask-image: linear-gradient(to top, transparent, rgb(122, 122, 122)),
      linear-gradient(to left, transparent 8px, rgb(122, 122, 122) 8px);
    mask-size: 100% 20000px;
    mask-position: left bottom;
    -webkit-mask-image: linear-gradient(to top, transparent, rgb(122, 122, 122)),
      linear-gradient(to left, transparent 8px, rgb(122, 122, 122) 8px);
    -webkit-mask-size: 100% 20000px;
    -webkit-mask-position: left bottom;
    transition: mask-position 0.125s, -webkit-mask-position 0.125s;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb:horizontal {
      width: 5px;
      border-radius: 12.5px;
      background: rgba(160, 160, 160, 0.75);
    }

    &:hover {
      mask-position: top left;
      -webkit-mask-position: left top;
    }

    @media screen and (max-width: 500px) {
      //width: 100vw;
      padding-top: 10px !important;
    }

    @media screen and (max-width: 1024px) {
      &::-webkit-scrollbar {
        width: 0px;
      }

      padding-top: 60px;
    }

    .reservation__timestamp {
      width: 100%;
      text-align: center;
      color: var(--color-text);
      opacity: 0.5;
      font-size: 12px;
      font-weight: 400;
      padding-top: 5px;
      animation: slideUp 0.25s ease;
    }

    .reservation__comment__wrapper {
      max-width: 65%;
      margin: 0.5rem 0.75rem;
      display: flex;
      position: relative;
      align-items: flex-end;

      &.call__detail {
        max-width: 100%;

        > div {
          text-align: center;
          color: var(--color-text);
          width: 100%;
          margin: 5px auto;

          span {
            background: rgba(39, 115, 182, 0.4);
            border: 1px solid rgba(37, 109, 173, 0.35);
            padding: 5px 12.5px;
            border-radius: 25px;
            font-size: 15px;

            svg {
              margin-right: 7.5px;
              vertical-align: -0.25rem;
            }
          }
        }

        .missed__call {
          span {
            filter: brightness(0.75);
            text-transform: capitalize;
            svg {
              color: red;
            }
          }
        }

        .call__duration {
          span {
            filter: brightness(0.75);

            svg {
              color: grey;
            }
          }
        }
      }

      a > .attachment > .file__name {
        width: 160px;
        white-space: pre;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @media only screen and (max-width: 720px) {
        max-width: 78%;

        a > .attachment > .file__name {
          width: 160px;
          white-space: pre;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .comment__tile {
        // width: 32px;
        // height: 32px;
        border-radius: 50%;
        z-index: 2;

        > img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          //display: none;
          object-fit: cover;
        }
      }

      .comment__content {
        position: relative;
        padding: 0.5rem 0.7rem 0.35rem 0.7rem;
        margin-left: 0.5rem;
        border-radius: 10px;
        z-index: 1;
        background: var(--color-textfield);
        border: 1px solid rgba(128, 128, 128, 0.05);
        color: var(--color-text);
        text-align: left;

        .received__check {
          text-align: right;
          position: absolute;
          bottom: -4px;
          right: 2.5px;

          svg {
            font-size: 15px;
          }

          .sent-check {
            color: grey;

          }

          .received-check {
            color: #1a72d6;
          }
        }

        &.attachment {
          display: flex;
          align-items: center;
          flex-direction: row;
          width: max-content;
          margin-bottom: -5px !important;

          .attachment__icon {
            display: block;
            width: 42px;
            height: 42px;
            margin-right: 1rem;
          }
        }

        &.link {
          display: flex;
          align-items: center;
          flex-direction: row;
          width: max-content;
          color: var(--color-doubt-link);
        }

        &.image {
          // background: none;
          padding: 0rem 0rem !important;
          .comment__image {
            height: 200px;
            width: 200px;
            object-fit: cover;
            object-position: top;
            display: block;
            cursor: pointer;
            background: var(--color-textfield);
            border: 2px solid var(--color-textfield);
            border-radius: 8px;
          }
        }
      }

      &.sender {
        .top {
          border-bottom-left-radius: 2px;
          margin-bottom: -2.5px;
          .comment__image {
            border-bottom-left-radius: 2px;
          }
        }

        .middle {
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
          margin-top: -2px;
          margin-bottom: -2px;
          .comment__image {
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
          }
        }

        .bottom {
          border-top-left-radius: 2px;
          margin-top: -2.5px;
          .comment__image {
            border-top-left-radius: 2px;
          }
        }
      }

      &.receiver {
        margin-left: auto;
        flex-direction: row-reverse;

        .comment__content {
          margin-right: 0.5rem;
          color: var(--color-text);
          margin-left: 0;
          padding: 0.5rem 1rem 0.5rem 0.7rem;

          &.top {
            border-bottom-right-radius: 2px;
            margin-bottom: -2px;
            .comment__image {
              border-bottom-right-radius: 2px;
            }
          }

          &.middle {
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            margin-top: -2px;
            margin-bottom: -2px;
            .comment__image {
              border-bottom-right-radius: 2px;
              border-bottom-right-radius: 2px;
            }
          }

          &.bottom {
            border-top-right-radius: 2px;
            margin-top: -1px;
            .comment__image {
              border-top-right-radius: 2px;
            }
          }
        }

        @media screen and (max-width: 500px) {
          .comment__tile {
            display: none;
          }

          .comment__content {
            margin-right: 0;
          }
        }
      }
    }
  }

  .blaze__chat__image__preview {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    position: absolute;
    bottom: 70px;
    z-index: 50;

    .image__preview {
      margin: 0.75rem 1rem;
      margin-bottom: -0.25rem;
      width: 100px;
      height: 105px;
      position: relative;
      animation: fadeOutDown 0.35s ease;

      .imagePreviewDialog_closeIcon {
        z-index: 1 !important;
        position: absolute;
        top: -10px;
        right: -10px;
        background: var(--color-highlight);
        color: white;
        border: 1.5px solid rgba(228, 228, 228, 0.808);
        border-radius: 50%;
        cursor: pointer;
      }
    }
    .image__preview > img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      background: var(--color-textfield);
      border: 2px solid var(--color-textfield);
      border-radius: 8px;
    }
  }
  .textarea__wrapper {
    width: 100%;
    border-radius: 24px;
    margin-left: 48px !important;
    margin-right: 0px;
    //background: var(--color-textfield);
    margin-bottom: -1px;
    background: transparent;
    display: flex;

    .blazeres__input {
      flex: 1;
      font-size: 15px;
      color: var(--color-text);
      // border: 1px solid #dde0e1;
      border: 1px solid var(--color-textfield);
      font-weight: 400;
      border-radius: 24px;
      //padding-left: 16px;
      padding-right: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      margin-left: 8px;
      margin-right: 8px;
      background: var(--color-textfield);
      outline: none !important;
      resize: none;

      &:disabled {
        cursor: not-allowed;
      }
    }
  }
  .user__chat__input {
    display: flex;
    align-items: center;
    padding: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.75rem;
    //position: absolute;
    //bottom: 0px;
    background: var(--blaze-chat);
    z-index: 51;
    width: 100%;
    .textarea__wrapper {
      width: 100%;
      border-radius: 24px;
      margin-left: 10px !important;
      margin-right: 0px;
      background: var(--color-textfield);
      display: flex;
      margin-bottom: -1px;
    }

    @media screen and (max-width: 500px) {
      //width: 100vw;
    }

    textarea {
      width: calc(100% - 28px);
      //margin-top: 3px;

      padding: 7.5px 10px;
      border: none;
      border-radius: 8px;
      &::-webkit-scrollbar-track {
        background-color: var(--color-textfield);
      }

      &::-webkit-scrollbar {
        margin-right: 1rem;
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--color-blaze-sidebar);
        cursor: pointer;
        border-radius: 8px;
      }
    }

    .blaze__image__picker {
      background: var(--color-focus);
      border-radius: 50%;
      width: 42px;
      height: 42px;
      text-align: center;
      //position: absolute;
      bottom: 14px;
      flex-shrink: 0;

      @media screen and (max-width: 500px) {
        bottom: 13px;
      }

      &.dark {
        background: var(--color-focus);
        .blaze__input__image {
          filter: saturate(0) invert(1) brightness(1.5);
        }
      }
    }

    .blaze__input__image {
      width: 22px;
      cursor: pointer;
      margin-top: 0.62rem;
      margin-left: 10px;
      filter: saturate(0) brightness(1);
    }

    .blazeres__btn {
      background: transparent;
      cursor: pointer;
      height: 40px;
      width: 44px;
      flex-shrink: 0;
      //margin-top: auto;

      .circular__progress__lottie {
        width: 45px;
        padding: 7px;
        margin-top: -0.25rem;
        filter: invert(0.75) grayscale(1);
        transform: translateX(-5px) scale(0.85);
      }
    }

    .blaze-send-icon {
      //padding-top: 4px;
      padding-left: 0px;
      height: 24px;
      width: 24px;
      stroke-width: 1px;
      margin: auto;
    }
    @media screen and (max-width: 500px) {
      padding: 0.7rem 0.5rem 1rem 0.5rem;

      .blazeres__btn {
        padding-left: 8px;
        padding-right: 0;

        .circular__progress__lottie {
          margin-top: -0.4rem !important;
        }
      }
    }
  }
}

.end__call {
  > div {
    position: absolute;
    top: 82%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--color-primary);
    padding: 20px;
    border-radius: 10px;
    animation: fadeIn 0.125s;

    h1 {
      font-weight: 600;
      color: var(--color-text);
      padding-bottom: 20px;
    }

    > div {
      display: flex;
      flex-direction: column;
      button {
        color: white;
        padding: 10px 15px;
        margin: 7px 10px;
        font-weight: 500;
        border-radius: 5px;
        cursor: pointer;
        width: 240px;
        font-size: 18px;
      }

      .yes__btn {
        background: #d93025;
      }

      .no__btn {
        background: #3f3f3f;
      }
    }
  }
}

@keyframes moveLeft {
  from {
    transform: scale(1) translateX(0);
  }
  to {
    transform: scale(0.8) translateX(-15%);
    border-radius: 15px;
  }
}

@keyframes moveRight {
  from {
    transform: scale(0.8) translateX(-15%);
    border-radius: 15px;
  }
  to {
    transform: scale(1) translateX(0);
  }
}

@keyframes slideInside {
  from {
    width: 0;
  }
  to {
    width: 360px;
  }
}

@keyframes slideOutside {
  from {
    width: 360px;
  }
  to {
    width: 0;
  }
}

@keyframes slideInsideToScreen {
  from {
    width: 0;
  }
  to {
    width: 100vw;
  }
}

@keyframes slideOutsideToScreen {
  from {
    width: 100vw;
  }
  to {
    width: 0;
  }
}
